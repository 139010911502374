<template>
  <v-card
    class="d-flex flex-column"
    :loading="timelineLoading"
  >
    <div class="pa-3 d-flex justify-space-between flex-wrap align-center">
      <span class="text-subtitle-1 font-medium">Riwayat {{ name }}</span>
      <v-chip-group
        active-class="primary--text"
        mandatory
        column
      >
        <v-chip
          small
          active
        >
          Semua
        </v-chip>
        <v-chip
          small
          active
        >
          Task
        </v-chip>
        <v-chip
          small
          active
        >
          Log
        </v-chip>
      </v-chip-group>
    </div>
    <v-divider />
    <div
      v-scroll.self="listenScrollFetchMoreTimeline"
      class="d-flex flex-column pa-4 scroll-timeline"
    >
      <div
        v-for="log in timelines"
        :key="log.id"
      >
        <div class="d-flex justify-space-between align-center my-2">
          <span class="d-flex align-center">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="29"
                  class="mb-1 v-avatar-light-bg cursor-pointer"
                  v-on="on"
                  @click="$router.push({ name: 'user-detail', params: { id: log.created_by.id} })"
                >
                  <span class="caption">{{ avatarText(log.created_by.id === store.getters.getUserData.id ? 'U' : log.created_by.name) }}</span>
                </v-avatar>
              </template>
              <span>{{ log.created_by.id === store.getters.getUserData.id ? 'Anda' : log.created_by.name }}</span>
            </v-tooltip>

            <!-- category tanpa from & to -->
            <span
              v-if="[1, 11, 13, 14, 15].includes(log.category_id)"
              class="caption mx-2 mb-1"
            >
              {{ messageList[log.category_id] }} {{ props.name }}
            </span>

            <!-- category date -->
            <span
              v-if="[4,5].includes(log.category_id)"
              class="caption mx-2 mb-1"
            >
              mengubah {{ messageList[log.category_id] }} {{ log.from ? `dari "${dateFormat(log.from_value)}"` : null }} menjadi "{{ dateFormat(log.to_value) }}"
            </span>

            <!-- category status -->
            <span
              v-if="log.category_id === 2"
              class="caption mx-2 mb-1"
            >
              men-set status {{ props.name }}
              <span v-if="log.from">
                dari
                <span class="primary--text font-medium">{{ mapSingle(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapSingle(log, 'to') }}</span>
            </span>

            <!-- category priority -->
            <span
              v-if="log.category_id === 3"
              class="caption mx-2 mb-1"
            >
              men-set prioritas {{ props.name }}
              <span v-if="log.from">
                dari
                <span class="primary--text font-medium">{{ mapSingle(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapSingle(log, 'to') }}</span>
            </span>

            <!-- category customer -->
            <span
              v-else-if="log.category_id === 7"
              class="caption mx-2 mb-1"
            >
              men-set customer
              <span v-if="log.from">
                dari
                <span class="primary--text font-medium">{{ mapSingle(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapSingle(log, 'to') }}</span>
            </span>

            <!-- category product -->
            <span
              v-else-if="log.category_id === 8"
              class="caption mx-2 mb-1"
            >
              men-set produk
              <span v-if="log.from.length">
                dari
                <span class="primary--text font-medium">{{ mapObjectValue(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapObjectValue(log, 'to') }}</span>
            </span>

            <!-- category tags -->
            <span
              v-else-if="log.category_id === 9"
              class="caption mx-2 mb-1"
            >
              men-set tags
              <span v-if="log.from.length">
                dari
                <span class="primary--text font-medium">{{ mapObjectValue(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapObjectValue(log, 'to') }}</span>
            </span>

            <!-- category assignee -->
            <span
              v-else-if="log.category_id === 6"
              class="caption mx-2 mb-1"
            >
              <span v-if="log.from.length && log.from_value.filter(user => !log.to.includes(user.id)).length">
                men-unassign
                <v-tooltip
                  v-for="user in log.from_value.filter(user => !log.to.includes(user.id))"
                  :key="'uf' + user.id"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      size="29"
                      class="mb-1 v-avatar-light-bg cursor-pointer mx-1"
                      v-on="on"
                      @click="$router.push({ name: 'user-detail', params: { id: user.id } })"
                    >
                      <span class="caption">{{ avatarText(user.name) }}</span>
                    </v-avatar>
                  </template>
                  <span>{{ user.name }}</span>
                </v-tooltip>
              </span>
              <span v-if="log.to.length && log.to_value.filter(user => !log.from.includes(user.id)).length">
                {{ log.from.length && log.from_value.filter(user => !log.to.includes(user.id)).length ? 'dan ' : '' }}men-assign
                <v-tooltip
                  v-for="user in log.to_value.filter(user => !log.from.includes(user.id))"
                  :key="'ut' + user.id"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      size="29"
                      class="mb-1 v-avatar-light-bg cursor-pointer mx-1"
                      v-on="on"
                      @click="$router.push({ name: 'user-detail', params: { id: user.id } })"
                    >
                      <span class="caption">{{ avatarText(user.name) }}</span>
                    </v-avatar>
                  </template>
                  <span>{{ user.name }}</span>
                </v-tooltip>
              </span>
            </span>

            <!-- category name -->
            <span
              v-if="log.category_id === 10"
              class="caption mx-2 mb-1"
            >
              mengubah nama {{ props.name }} {{ log.from ? `dari "${log.from_value}"` : null }} menjadi "{{ log.to_value }}"
            </span>

            <!-- category custom attribute text -->
            <span
              v-if="log.category_id === 12 && [1,2].includes(log.custom_attribute.data_type.id)"
              class="caption mx-2 mb-1"
            >
              men-set {{ log.custom_attribute.name }} {{ log.from ? `dari "${log.from_value}"` : null }} menjadi "{{ log.to_value }}"
            </span>

            <!-- category custom attribute date -->
            <span
              v-if="log.category_id === 12 && log.custom_attribute.data_type.id === 3"
              class="caption mx-2 mb-1"
            >
              men-set {{ log.custom_attribute.name }} {{ log.from ? `dari "${dateFormat(log.from_value)}"` : null }} menjadi "{{ dateFormat(log.to_value) }}"
            </span>

            <!-- category custom attribute with options -->
            <span
              v-if="log.category_id === 12 && [4, 5].includes(log.custom_attribute.data_type.id)"
              class="caption mx-2 mb-1"
            >
              men-set {{ log.custom_attribute.name }}
              <span v-if="(log.custom_attribute.data_type.id === 4 && log.from) || (log.custom_attribute.data_type.id === 5 && log.from.length)">
                dari
                <span class="primary--text font-medium">{{ mapObjectValue(log, 'from') }}</span>
              </span>
              menjadi
              <span class="primary--text font-medium">{{ mapObjectValue(log, 'to') }}</span>
            </span>
          </span>
          <span class="caption">{{ dateFormat(log.created_at) }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import useTimeline from '@/composables/useTimeline'
import dateFormat from '@/utils/dateFormat'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    objectId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const {
      timelineLoading,
      timelines,
      listenScrollFetchMoreTimeline,
      fetchReferenceLogs,
    } = useTimeline({ [`${props.type}_id`]: +props.objectId })

    const mapObjectValue = (log, type) => {
      if (log[type] === null) {
        return '-'
      }

      if (Array.isArray(log[type])) {
        return log[type].map(el => (log[`${type}_value`].find(x => x.id === el) || { name: '(terhapus)' }).name).join(', ')
      }

      return (log[`${type}_value`].find(x => x.id === log[type]) || { name: '(terhapus)' }).name
    }

    const mapSingle = (log, type) => {
      if (log[type] === null) {
        return '-'
      }

      return (log[type] && log[`${type}_value`] === null ? '(terhapus)' : log[`${type}_value`])
    }

    const messageList = {
      1: 'membuat',
      11: 'mengubah deskripsi',
      13: 'men-archive',
      14: 'men-unarchive',
      15: 'menghapus',
      4: 'tanggal akhir',
      5: 'tanggal mulai',
    }

    onMounted(() => {
      fetchReferenceLogs()
    })

    return {
      props,
      store,
      timelines,
      messageList,
      timelineLoading,

      mapObjectValue,
      mapSingle,
      avatarText,
      dateFormat,
      listenScrollFetchMoreTimeline,
    }
  },
}
</script>
<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scroll-timeline {
  @include style-scroll-bar();
  max-height: 30em;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
